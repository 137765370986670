import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
// import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
// import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import '../styles/blog-page.css'
import { StaticImage } from "gatsby-plugin-image";
import BlogsGrid from "../components/blogs/blogsGrid"
import Navbar from "../components/Navbar/Navbar";
import BlockContent from "../components/block-content"
function TermsRoute ({ pageContext }) {
    // const { data, errors,pageContext } = props;
    const { tnc } = pageContext

  if (tnc == null) {
    return (
      <Layout>
        <GraphQLErrorList  />
      </Layout>
    );
  }

  const site = (tnc || {}).site;
  // const termsOfUse = (tnc || {})._rawBody


  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   );
  // }
// console.log(termsOfUse);
  return (
    <Layout>
        {/* <SEO title={site.title} description={site.description} keywords={["tags"]} /> */}
        <div className="category-banner">
      <Navbar/>
      <div className="category-name">
        {tnc.title}
      </div>

        </div>

           
      <div className="padding-20" >
     
      {tnc._rawTnc && <BlockContent blocks={tnc._rawTnc || []} />}
      </div>

  
    </Layout>
  );
  
}

export default TermsRoute;

// export const tagPageQuery = graphql`
//   query TagPage($tag: String) {
    
//     site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
//         title
//         description
//         keywords
//     }
//     tnc: allSanityTermsAndConditions {
//         edges {
//           node {
//             tnc {
//               _rawChildren
//               _key
//             }
//           }
//         }
//       }
//   }
// `;
